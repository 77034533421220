import {
  type AllPaymentMethodsInput,
  type CaptureOrderUpsert,
  CaptureStatus,
  Currency,
  CustomerCashAccountDocument,
  CustomerReceiptsPaginatedDocument,
  MyActiveVouchersDocument,
  type Order,
  OrderDocument,
  type OrderError,
  type OrderItemInput,
  type OrderPaymentIntent,
  type OrderVoucherInput,
  PaymentMethodType,
  SourceBusinessHoursDocument,
  SourceType,
  UpsertOrderDocument,
  type UpsertOrderMutation,
  UpsertOrderWithPaymentIntentDocument,
  type UpsertOrderWithPaymentIntentMutation,
  ValidateFulfillmentTimeDocument,
  ValidateFulfillmentTimeResultType,
} from "@/generated/requests/pos";
import { FindFastestStoreDocument, type PublicStoreInfo } from "@/generated/requests/services";
import { Service } from "@/lib/apollo";
import { useLazyCustomerQuery, useLazyPOSQuery, usePOSMutation } from "@/lib/apollo-hooks";
import { trackPurchaseWrapper } from "@/lib/helpers";
import { captureOrder, finalizeOrderUpsert } from "@/static/lib/old-order-requests";
import { fetchLocal, storeLocal } from "@/static/lib/util";
import { type DocumentNode, type FetchResult, useApolloClient } from "@apollo/client";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { createContext, useContext, useState } from "react";
import useGeoCoordinates from "../../hooks/useGeoCoordinates";
import { useGetCustomerRewardSummary, useGetOrder } from "../../operations/queries";
import CheckoutOrderProcessing from "../../organisms/CheckoutOrderProcessing/CheckoutOrderProcessing";
import ExtraBakeTimeModal from "../../organisms/ExtraBakeTimeModal/ExtraBakeTimeModal";
import OutOfRangeDeliveryModal from "../../organisms/OutOfRangeDeliveryModal/OutOfRangeDeliveryModal";
import { useCustomerContext } from "../CustomerContext/CustomerContext";
import { buildOrderUpsert } from "./helpers";
import ReceiptRewardAnimationModal from "../../organisms/OrderReceipt/ReceiptRewardAnimation/ReceiptRewardAnimationModal";

dayjs.extend(utc);
dayjs.extend(timezone);

export const LS_ORDER = "crumblOrderId";
const LS_TIME_SLOT = "crumblOrderTimeSlot";
const LS_DELIVERY_ADDRESS = "crumblOrderDeliveryAddress";
const LS_LARGE_ORDER = "crumblIsLargeOrder";
const LS_PREFERRED_STORE_SLUG = "crumblPreferredStoreSlug";
const LS_LAST_REMOVED_ITEM = "crumblLastRemovedItem";

const sourceTypeToTextMap = {
  [SourceType.CarryOut]: "Pickup",
  [SourceType.Delivery]: "Delivery",
};

export type OrderProcessingState = {
  type: OrderProcessingStateType;
  error?: string;
  validationErrors?: OrderError[];
};

export enum OrderProcessingStateType {
  NotProcessing = "NOT_PROCESSING",
  Finalizing = "FINALIZING",
  FailedFinalizing = "FAILED_FINALIZING",
  Finalized = "FINALIZED",
  Confirming = "CONFIRMING",
  FailedConfirming = "FAILED_CONFIRMING",
  Confirmed = "CONFIRMED",
  Capturing = "CAPTURING",
  FailedCapture = "FAILED_CAPTURE",
  Captured = "CAPTURED",
}

type FulfillmentTypeResponseType = {
  message: string;
  responseType: ValidateFulfillmentTimeResultType;
  newTime: string;
  isNewTime?: boolean;
};

type CheckFulfillMentTimePropsType = {
  callback?: () => void;
  additionalCost?: number;
  source?: any;
  storeId?: string;
};

type FulfillmentTypePropsType = {
  additionalCost?: number;
  source?: any;
  storeId?: string;
};

export type UpsertOrderItemsPropsType = {
  order?: any;
  storeId?: string;
  sourceId?: string;
  items?: OrderItemInput[] | any[];
  timeSlot?: string;
  paymentMethods?: AllPaymentMethodsInput;
  tip?: { amount: number; currency: Currency };
  rewardProducts?: { rewardProductId: string }[];
  vouchers?: OrderVoucherInput[];
  updatedSourceType?: SourceType;
  calculatePaymentMethods?: boolean;
  requestStripePaymentIntent?: boolean;
  sourceType?: SourceType;
  isDigitalGiftCard?: boolean;
};

export type OrderContextNewType = {
  // Order details
  orderId: string;
  order: Order;
  orderError: any;
  orderLoading: boolean;
  orderTimeSlot: string | null;
  orderProcessingState: OrderProcessingState;
  orderIsUpdating: boolean;
  isLargeOrder: boolean;
  preferredStoreSlug: string;
  tempCateringStoreSlug?: string;
  fulfillmentTimeMessage: string;
  orderNote?: string;
  carryOutPickupName?: string;
  isUpsertLoading: boolean;
  isCaptureOrderLoading?: boolean;
  deliveryAddress: any;
  lastRemovedItem: OrderItemInput;

  // State setters
  setTimeSlot: (timeSlot: string | null) => void;
  setDeliveryAddress: (address: any) => Promise<PublicStoreInfo>;
  setIsLargeOrder: (isLargeOrder: boolean) => void;
  setLastRemovedItem: (item: OrderItemInput) => void;
  setPreferredStoreSlug: (slug: string) => void;
  setOrderIsUpdating: (orderIsUpdating: boolean) => void;
  setOrderProcessingState: (orderProcessingState: OrderProcessingState) => void;
  setCarryOutPickupName?: (name: string) => void;
  setOrderNote?: (note: string) => void;
  setTempCateringStoreSlug?: (slug: string) => void;
  setIsOutOfRangeModalOpen: (isOutOfRangeModalOpen: boolean) => void;

  // Order-related actions
  upsertOrderItems: ({
    order,
    sourceId,
    items,
    timeSlot,
    paymentMethods,
    tip,
    rewardProducts,
    vouchers,
    requestStripePaymentIntent,
  }: UpsertOrderItemsPropsType) => Promise<FetchResult<UpsertOrderWithPaymentIntentMutation>>;
  finalizeOrder: ({
    updatedOrder,
    isGiftCardOrder,
  }: { updatedOrder?: Order; isGiftCardOrder?: boolean }) => Promise<OrderPaymentIntent | boolean>;
  clearOrder: ({
    saveTimeSlot,
    saveDeliveryAddress,
    saveIsLargeOrder,
  }?: {
    saveTimeSlot?: boolean;
    saveDeliveryAddress?: boolean;
    saveIsLargeOrder?: boolean;
  }) => void;
  kickoffOrderCapture: () => Promise<void>;
  checkCaptureStatus: () => Promise<void>;
  checkFulfillmentTime: (obj: CheckFulfillMentTimePropsType) => Promise<void>;
  handleValidateFulfillmentTime: (obj: CheckFulfillMentTimePropsType) => Promise<FulfillmentTypeResponseType>;
  getNextAvailableTimeSlot: ({
    storeId,
    orderSourceType,
    date,
  }: { storeId: string; orderSourceType: SourceType; date: string }) => Promise<string>;
};

const OrderContextNew = createContext<OrderContextNewType>(null);

export const OrderContextNewProvider = ({ children }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { customer } = useCustomerContext();
  const apolloClient = useApolloClient();
  const { fetchMostAccurateGeoCoordinatesFromAddress } = useGeoCoordinates();
  const [showReceiptRewardAnimationModal, setShowReceiptRewardAnimationModal] = useState(false);

  // Local storage retrieval
  const orderId = fetchLocal<string>(LS_ORDER);
  const localStorageTimeSlot = fetchLocal<string>(LS_TIME_SLOT);
  const localStorageLargeOrder = fetchLocal<boolean>(LS_LARGE_ORDER);
  const localPreferredStoreSlug = fetchLocal<string>(LS_PREFERRED_STORE_SLUG);
  const localLastRemovedItem = fetchLocal<OrderItemInput>(LS_LAST_REMOVED_ITEM);
  const localStorageDeliveryAddress = fetchLocal<any>(LS_DELIVERY_ADDRESS);

  // State variables
  const [orderTimeSlot, setOrderTimeSlot] = useState<string | null>(localStorageTimeSlot || undefined);
  const [isLargeOrder, setIsLargeOrderState] = useState<boolean>(localStorageLargeOrder || false);
  const [preferredStoreSlug, setPreferredStoreSlugState] = useState<string | null>(localPreferredStoreSlug);
  const [lastRemovedItem, setLastRemovedItemState] = useState<OrderItemInput>(localLastRemovedItem || undefined);
  const [deliveryAddress, setAddress] = useState(localStorageDeliveryAddress || undefined);
  const [carryOutPickupName, setCarryOutPickupName] = useState<string | null>(undefined);
  const [orderNote, setOrderNote] = useState<string | null>(undefined);
  const [tempCateringStoreSlug, setTempCateringStoreSlug] = useState<string | null>(undefined);
  const [fulfillmentTimeMessage, setFulfillmentTimeMessage] = useState<string>(undefined);
  const [tempBagTotal, setTempBagTotal] = useState<number>(0);
  const [isOutOfRangeModalOpen, setIsOutOfRangeModalOpen] = useState<boolean>(false);
  const [isOpenExtraBakeTimeModal, setIsOpenExtraBakeTimeModal] = useState(false);
  const [isCaptureOrderLoading, setIsCaptureOrderLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [adjustFulfillmentTimeLoading, setAdjustFulfillmentTimeLoading] = useState<boolean>(false);
  const [orderIsUpdating, setOrderIsUpdating] = useState<boolean>(false);
  const [orderProcessingState, setOrderProcessingState] = useState<OrderProcessingState>({
    type: OrderProcessingStateType.NotProcessing,
  });
  const isQuickOrder = router.query?.isQuickOrder === "true";

  // Abort controllers
  const [abortController, setAbortController] = useState<AbortController>();
  const [captureAbortController, setCaptureAbortController] = useState<AbortController>();

  // Fulfillment time-related states
  const [tempAdjustedFulfillmentTime, setTempAdjustedFulfillmentTime] = useState<{
    adjustedFullfillmentTime: string;
    type: ValidateFulfillmentTimeResultType;
  }>(undefined);

  const [earliestAvailableFulfillmentTime, setEarliestAvailableFulfillmentTime] = useState<{
    adjustedFullfillmentTime: string;
    type: ValidateFulfillmentTimeResultType;
  }>(undefined);

  const [modalConfirmCallback, setModalConfirmCallback] = useState<() => any>(null);
  const [fulfillmentModalDescription, setFulfillmentModalDescription] = useState<string>("");

  // GraphQL Mutations & Queries
  const [upsertOrderMutationWithPaymentIntent] = usePOSMutation<
    UpsertOrderWithPaymentIntentMutation,
    { upsert: CaptureOrderUpsert }
  >(UpsertOrderWithPaymentIntentDocument);

  const [upsertOrderMutation] = usePOSMutation<UpsertOrderMutation, { upsert: CaptureOrderUpsert }>(
    UpsertOrderDocument,
  );

  const [findFastestStore] = useLazyCustomerQuery(FindFastestStoreDocument);
  const [validateFulfillmentTime] = useLazyPOSQuery(ValidateFulfillmentTimeDocument);
  const [getBusinessHours] = useLazyPOSQuery(SourceBusinessHoursDocument);
  const [getCustomerOrderHistory] = useLazyPOSQuery(CustomerReceiptsPaginatedDocument);

  const { refetch: refetchCustomerRewardSummary } = useGetCustomerRewardSummary({
    customerId: customer?.userId,
  });

  // Order data from server
  const { data: orderData, loading: orderLoading, error: orderError, networkStatus } = useGetOrder();
  // @ts-ignore
  const order: Order = orderData?.public?.order;

  const setDeliveryAddress = async (address: any) => {
    setIsLoading(true);
    try {
      if (!address?.latitude || !address?.longitude) {
        const geoCoordinates = await fetchMostAccurateGeoCoordinatesFromAddress(address);
        address.latitude = geoCoordinates?.latitude;
        address.longitude = geoCoordinates?.longitude;
      }

      const input = { addressId: address?.addressId, dateTime: orderTimeSlot };
      const storeResult = await findFastestStore({ variables: { input } });
      const store = storeResult?.data?.delivery?.findFastestStore?.store as PublicStoreInfo;

      if (!store) {
        throw new Error("Invalid address");
      }
      setAddress(address);
      storeLocal(LS_DELIVERY_ADDRESS, address);

      // if there is an order already created, and the store is different, clear the order
      if (order && order?.storeId !== store?.storeId) {
        await clearOrder({ saveTimeSlot: true, saveDeliveryAddress: true, saveIsLargeOrder: isLargeOrder });
      }

      return store;
    } catch (error) {
      console.error("Error setting delivery address:", error);
      throw new Error("Error setting delivery address:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const setTimeSlot = (timeSlot: string | null) => {
    setIsLoading(true);
    try {
      setOrderTimeSlot(timeSlot);
      storeLocal(LS_TIME_SLOT, timeSlot);
    } catch (error) {
      console.error("Error setting time slot:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const setIsLargeOrder = (isLargeOrder: boolean) => {
    setIsLargeOrderState(isLargeOrder);
    storeLocal(LS_LARGE_ORDER, isLargeOrder);
  };

  const setPreferredStoreSlug = (slug: string) => {
    setPreferredStoreSlugState(slug);
    storeLocal(LS_PREFERRED_STORE_SLUG, slug);
  };

  const setLastRemovedItem = (item: OrderItemInput) => {
    setLastRemovedItemState(item);
    storeLocal(LS_LAST_REMOVED_ITEM, item);
  };

  const handleAdjustFulfillmentTime = async () => {
    setAdjustFulfillmentTimeLoading(true);
    try {
      setTimeSlot(tempAdjustedFulfillmentTime?.adjustedFullfillmentTime);
      await modalConfirmCallback();
      setIsOpenExtraBakeTimeModal(false);
      setModalConfirmCallback(undefined);
      setFulfillmentTimeMessage(undefined);
      // wait for the modal to close before clearing the temp adjusted fulfillment time
      setTimeout(() => {
        setTempAdjustedFulfillmentTime(undefined);
        setEarliestAvailableFulfillmentTime(undefined);
      }, 1000);
    } catch (error) {
      console.error("Error adjusting fulfillment time", error);
    } finally {
      setAdjustFulfillmentTimeLoading(false);
    }
  };

  const handleCloseExtraBakeTimeModal = () => {
    setIsOpenExtraBakeTimeModal(false);
    setModalConfirmCallback(undefined);
    // wait for the modal to close before clearing the temp adjusted fulfillment time
    setTimeout(() => {
      setTempAdjustedFulfillmentTime(undefined);
      setEarliestAvailableFulfillmentTime(undefined);
    }, 1000);
  };

  const handleSelectNewFlavorForNextWeek = () => {
    clearOrder({ saveTimeSlot: true, saveDeliveryAddress: true, saveIsLargeOrder: isLargeOrder });
    setTimeSlot(tempAdjustedFulfillmentTime?.adjustedFullfillmentTime); // This will ne the new time next week
    setIsOpenExtraBakeTimeModal(false);
    setModalConfirmCallback(undefined);
    // wait for the modal to close before clearing the temp adjusted fulfillment time
    setTimeout(() => {
      setTempAdjustedFulfillmentTime(undefined);
      setEarliestAvailableFulfillmentTime(undefined);
    }, 1000);
  };

  const clearOrder = async ({ saveTimeSlot = false, saveDeliveryAddress = false, saveIsLargeOrder = false } = {}) => {
    storeLocal(LS_ORDER, null);
    setOrderProcessingState({ type: OrderProcessingStateType.NotProcessing });
    setOrderNote(null);

    if (!saveIsLargeOrder) {
      setIsLargeOrder(false);
    }

    if (!saveDeliveryAddress) {
      storeLocal(LS_DELIVERY_ADDRESS, null);
      setAddress(null);
    }

    if (!saveTimeSlot) {
      storeLocal(LS_TIME_SLOT, null);
      setOrderTimeSlot(null);
    }

    // refresh things that need to be refreshed, if a user used vouchers or reward products etc
    const refetchDocuments: DocumentNode[] = [];

    if (order?.vouchers?.length) {
      refetchDocuments.push(MyActiveVouchersDocument);
    }
    if (order?.paymentMethods?.accounts?.length) {
      refetchDocuments.push(CustomerCashAccountDocument);
    }
    if (customer?.userId) {
      refetchDocuments.push(CustomerReceiptsPaginatedDocument);
    }
    // Optionally refetch queries if needed
    await apolloClient.refetchQueries({
      // @ts-ignore
      include: refetchDocuments.map((document) => {
        const isCustomerCashDocument = document?.definitions?.find(
          (def) => def?.kind === "OperationDefinition" && def?.name?.value === "CustomerCashAccount",
        );
        const isCustomerReceiptsDocument = document?.definitions?.find(
          (def) => def?.kind === "OperationDefinition" && def?.name?.value === "CustomerReceiptsPaginated",
        );

        return {
          query: document,
          variables: isCustomerCashDocument
            ? {
                currency: order?.totals?.total?.currency || Currency.Usd,
              }
            : isCustomerReceiptsDocument
              ? {
                  limit: 20,
                }
              : {},
          context: {
            service: Service.pos,
          },
        };
      }),
    });
    if (order?.rewardProducts?.length) {
      await refetchCustomerRewardSummary();
    }
    apolloClient.cache.evict({ id: `Order:${order?.orderId}` });
  };

  const upsertOrderItems = async ({
    order,
    sourceId,
    items,
    timeSlot = orderTimeSlot,
    paymentMethods,
    tip = undefined,
    rewardProducts = undefined,
    vouchers = undefined,
    requestStripePaymentIntent = false,
    sourceType,
    isDigitalGiftCard = false,
  }: UpsertOrderItemsPropsType) => {
    setIsLoading(true);
    const orderItems = items || order?.items;
    const orderTip = tip || order?.tip;
    const orderPaymentMethods = paymentMethods || order?.paymentMethods;
    const orderSourceId = sourceId || order?.source?.sourceId;

    try {
      const orderUpsert = buildOrderUpsert({
        order,
        sourceId: orderSourceId,
        orderItems,
        timeSlot,
        deliveryAddress,
        paymentMethods: orderPaymentMethods,
        orderTip,
        customerId: customer?.userId,
        notes: orderNote,
        rewardProducts,
        vouchers,
        isLargeOrder,
        sourceType: sourceType || order?.source?.type,
        isDigitalGiftCard,
      });

      let result;
      if (!requestStripePaymentIntent) {
        result = await upsertOrderMutation({
          variables: {
            upsert: orderUpsert,
          },
        });
      } else {
        result = await upsertOrderMutationWithPaymentIntent({
          variables: {
            upsert: orderUpsert,
            // @ts-ignore
            paymentIntentInput: {
              usingPhysicalCard: false,
              nonCardPaymentMethods: orderPaymentMethods?.accounts || [],
            },
          },
        });
      }
      const resultingOrderId = result.data.public.upsertOrder.order.orderId;

      // set the order id in local storage
      storeLocal(LS_ORDER, resultingOrderId);

      // refetch the order
      await apolloClient.query({
        query: OrderDocument,
        variables: {
          orderOrReceiptId: resultingOrderId,
        },
        context: {
          service: Service.pos,
        },
      });

      return result;
    } catch (error) {
      console.error("Error upserting order:", JSON.stringify(error, null, 2));
    } finally {
      setIsLoading(false);
    }
  };

  const finalizeOrder = async ({
    updatedOrder,
    isGiftCardOrder,
  }: { updatedOrder?: Order; isGiftCardOrder?: boolean }): Promise<OrderPaymentIntent | boolean> => {
    const finalOrder = updatedOrder || order;
    try {
      setOrderProcessingState({ type: OrderProcessingStateType.Finalizing });
      if (abortController) {
        abortController.abort();
      }
      const newAbortController = new AbortController();
      setAbortController(newAbortController);
      setOrderIsUpdating(true);

      const orderTotal = finalOrder?.totals?.total?.amount;
      const orderPaymentMethodsAccountsTotal =
        finalOrder?.paymentMethods?.accounts?.reduce((acc, account) => acc + account.amount.amount, 0) || 0;
      const orderPaymentMethodsCardsTotal =
        finalOrder?.paymentMethods?.card?.reduce((acc, card) => acc + card.amount.amount, 0) || 0;

      // if the order was changed, update the payment methods to reflect the correct total
      let updatedOrder = finalOrder;
      if (orderPaymentMethodsAccountsTotal + orderPaymentMethodsCardsTotal !== orderTotal) {
        const cardRemainder = orderTotal - orderPaymentMethodsAccountsTotal;
        const result = await upsertOrderItems({
          order: finalOrder,
          paymentMethods: {
            accounts: orderPaymentMethodsAccountsTotal ? finalOrder?.paymentMethods?.accounts : [],
            card: cardRemainder
              ? [
                  {
                    type: PaymentMethodType.Card,
                    amount: {
                      amount: cardRemainder || orderTotal,
                      currency: finalOrder?.totals?.total?.currency || Currency.Usd,
                    },
                    usingPhysicalCard: false,
                  },
                ]
              : [],
          },
          isDigitalGiftCard: isGiftCardOrder,
        });
        const resultingOrderId = result.data.public.upsertOrder.order.orderId;

        const { data } = await apolloClient.query({
          query: OrderDocument,
          variables: {
            orderOrReceiptId: resultingOrderId,
          },
          context: {
            service: Service.pos,
          },
        });
        // @ts-ignore
        updatedOrder = data?.public?.order;
      }

      // get the order payment intent
      const paymentIntentResult = await upsertOrderItems({
        order: updatedOrder,
        requestStripePaymentIntent: true,
        isDigitalGiftCard: isGiftCardOrder,
      });

      const paymentIntent = paymentIntentResult?.data?.public?.upsertOrder?.paymentIntentForOrder;

      const orderUpsert = buildOrderUpsert({
        order: updatedOrder,
        deliveryAddress,
        timeSlot: orderTimeSlot,
        pickupName: carryOutPickupName || customer?.name,
        paymentIntent: paymentIntent,
        notes: orderNote,
        customerEmail: customer?.email || "",
        isLargeOrder,
        isDigitalGiftCard: isGiftCardOrder,
      });

      const result = await finalizeOrderUpsert({
        input: orderUpsert,
        abortSignal: newAbortController.signal,
      });

      // Double check and make sure we aren't in the middle of capturing or that the order isn't already captured.
      if (result.order?.captureStatus) {
        if (result.order.captureStatus === CaptureStatus.Captured) {
          // setOrderProcessingState({ type: OrderProcessingStateType.Captured });
          return true;
        } else if (result.order.captureStatus === CaptureStatus.Capturing) {
          setOrderProcessingState({ type: OrderProcessingStateType.Capturing });
          return true;
        }
      }

      // If there are no errors, we can proceed to the confirmation/capture steps
      if (!result.errorsV2?.length) {
        setOrderProcessingState({ type: OrderProcessingStateType.Finalized });
        return paymentIntent || true; // if there is no payment intent, it means a $0 dollar order
      } else {
        setOrderProcessingState({ type: OrderProcessingStateType.FailedFinalizing, validationErrors: result.errorsV2 });
        return false;
      }
    } catch (err) {
      console.error("Failed to finalize the order", err);
      setOrderProcessingState({ type: OrderProcessingStateType.FailedFinalizing, error: err.message });
      return false;
    } finally {
      setOrderIsUpdating(false);
      setAbortController(null);
    }
  };

  const orderComplete = async () => {
    try {
      const { data } = await getCustomerOrderHistory({ variables: { limit: 1 } });
      const receiptsLength = data?.customer?.customerReceiptsPaginated?.receipts?.length;
      const isNewCustomer = receiptsLength > 0 ? false : !data?.customer?.customer?.userId ? undefined : true;

      trackPurchaseWrapper(order, { isQuickOrder, newCustomer: isNewCustomer });
    } catch (error) {
      console.error("Error tracking purchase:", error);
      // We don't want to throw an error here as it's not critical to the order process
    }

    // redirect to the receipt page and show the receipt crumb animation modal
    await router.push(`/r/${order?.formattedReceipt?.receiptId}`);
    customer?.userId && setShowReceiptRewardAnimationModal(true);

    // clear the order object from memory and localStorage
    setTimeout(() => {
      clearOrder();
    }, 500);
  };

  const kickoffOrderCapture = async () => {
    try {
      setOrderProcessingState({ type: OrderProcessingStateType.Capturing });
      await checkCaptureStatus();
    } catch (err) {
      setOrderProcessingState({ type: OrderProcessingStateType.FailedCapture, error: err.message });
    }
  };

  const checkCaptureStatus = async () => {
    // NOTE: Checking capture status in progress, don't run another check.
    if (captureAbortController) {
      return;
    }

    // Lock check capture status to prevent multiple calls
    const newAbortController = new AbortController();
    setCaptureAbortController(newAbortController);

    try {
      setIsCaptureOrderLoading(true);
      const orderId = order?.orderId;
      const { captureStatus, errors } = await captureOrder({ orderId, abortSignal: newAbortController.signal });

      switch (captureStatus) {
        case CaptureStatus.Captured:
          setOrderProcessingState({ type: OrderProcessingStateType.Captured });
          await new Promise((resolve) => setTimeout(resolve, 1000));
          await orderComplete();
          break;
        case CaptureStatus.Failed:
          setOrderProcessingState({ type: OrderProcessingStateType.FailedCapture, validationErrors: errors });
          break;
        case CaptureStatus.Capturing:
          setOrderProcessingState({ type: OrderProcessingStateType.Capturing });
          break;
      }
    } finally {
      setIsCaptureOrderLoading(false);
      setCaptureAbortController(null);
    }
  };

  const checkFulfillmentTime = async ({
    callback,
    additionalCost = 0,
    source,
    storeId,
  }: CheckFulfillMentTimePropsType): Promise<void> => {
    if (!tempAdjustedFulfillmentTime?.adjustedFullfillmentTime) {
      const fulfillmentTimeResponse = await handleValidateFulfillmentTime({
        additionalCost,
        source,
        storeId,
      });

      if (fulfillmentTimeResponse?.isNewTime) {
        setTempAdjustedFulfillmentTime({
          adjustedFullfillmentTime: fulfillmentTimeResponse?.newTime,
          type: fulfillmentTimeResponse?.responseType,
        });
        setEarliestAvailableFulfillmentTime({
          adjustedFullfillmentTime: fulfillmentTimeResponse?.newTime,
          type: fulfillmentTimeResponse?.responseType,
        });

        setIsOpenExtraBakeTimeModal(true);
        setModalConfirmCallback(() => callback);
      } else {
        return Promise.resolve(callback());
      }
    } else {
      setIsOpenExtraBakeTimeModal(true);
      setModalConfirmCallback(() => callback);
    }
  };

  const handleValidateFulfillmentTime = async ({
    additionalCost = 0,
    source,
    storeId,
  }: FulfillmentTypePropsType): Promise<FulfillmentTypeResponseType> => {
    let messageResponse = undefined;
    let isNewTime = false;

    const cartTotal = (order?.totals?.subtotal?.amount || 0) + additionalCost;
    const fulfillmentTimeAdjustmentThreshold = source?.fulfillmentTimeAdjustmentThreshold;
    setTempBagTotal(cartTotal);

    if (cartTotal >= fulfillmentTimeAdjustmentThreshold) {
      const timeValidationResponse = await validateFulfillmentTime({
        variables: {
          input: {
            pickupTime: orderTimeSlot,
            proposedOrderAmount: Math.round(additionalCost + (order?.totals?.subtotal?.amount || 0)),
            sourceId: source?.sourceId,
            storeId: storeId,
          },
        },
      });
      const newTime = timeValidationResponse?.data?.public?.validateFulfillmentTime?.adjustedFullfillmentTime;
      const responseType: ValidateFulfillmentTimeResultType =
        timeValidationResponse?.data?.public?.validateFulfillmentTime?.type;
      if (
        !!tempAdjustedFulfillmentTime?.adjustedFullfillmentTime ||
        (!tempAdjustedFulfillmentTime && dayjs(newTime).isAfter(dayjs(orderTimeSlot))) ||
        responseType === ValidateFulfillmentTimeResultType?.OverMaximumForDay ||
        responseType === ValidateFulfillmentTimeResultType?.NewDayNextWeek
      ) {
        setTempAdjustedFulfillmentTime(timeValidationResponse?.data?.public?.validateFulfillmentTime);
        setEarliestAvailableFulfillmentTime(timeValidationResponse?.data?.public?.validateFulfillmentTime);
        isNewTime = true;
        if (responseType === ValidateFulfillmentTimeResultType?.SameDay) {
          messageResponse = t("order:new_pickup_delivery_time_x_y", {
            x: sourceTypeToTextMap?.[source?.type],
            y: dayjs(newTime)?.format("h:mm a"),
          });
          setFulfillmentModalDescription(t("order:fulfillment_description_time"));
        } else if (responseType === ValidateFulfillmentTimeResultType?.NewDaySameWeek) {
          messageResponse = t("order:new_pickup_delivery_date_x_y", {
            x: sourceTypeToTextMap?.[source?.type],
            y: dayjs(newTime)?.format("dddd, h:mm a"),
          });
          setFulfillmentModalDescription(t("order:fulfillment_description_date_and_time"));
        } else if (responseType === ValidateFulfillmentTimeResultType?.NewDayNextWeek) {
          setFulfillmentModalDescription(t("order:looks_lik_you_need_to_reduce_your_order"));
        }
      }
      return { message: messageResponse, responseType, newTime, isNewTime };
    } else {
      setTempAdjustedFulfillmentTime(undefined);
      setEarliestAvailableFulfillmentTime(undefined);
      return { message: "", responseType: undefined, newTime: undefined };
    }
  };

  const getNextAvailableTimeSlot = async ({ storeId, orderSourceType, date }) => {
    const selectedDate = date ? dayjs(date).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD");

    const fetchTimeSlots = async (selectedDate) => {
      const result = await getBusinessHours({
        variables: {
          storeId,
          type: orderSourceType,
          selectedDate,
        },
      });
      return result?.data?.public?.sourceForStore?.businessHoursForDay?.pickupTimeSlots || [];
    };

    const businessHoursData = await fetchTimeSlots(selectedDate);
    const firstAvailableTime = businessHoursData.find((slot) => slot?.isAvailable)?.startTimestamp;

    if (firstAvailableTime) return firstAvailableTime;

    // If no available slot today, check the next day
    const nextDay = dayjs(selectedDate).add(1, "day").format("YYYY-MM-DD");
    const nextDayBusinessHoursData = await fetchTimeSlots(nextDay);
    return nextDayBusinessHoursData.find((slot) => slot?.isAvailable)?.startTimestamp || null;
  };

  const contextValue = {
    // Order details
    orderId,
    order,
    networkStatus,
    orderLoading,
    orderError,
    isUpsertLoading: isLoading,
    isCaptureOrderLoading,
    orderProcessingState,
    orderIsUpdating,
    isLargeOrder,
    preferredStoreSlug,
    tempCateringStoreSlug,
    fulfillmentTimeMessage,
    orderNote,
    carryOutPickupName,
    orderTimeSlot,
    deliveryAddress,
    lastRemovedItem,

    // State setters
    setTimeSlot,
    setDeliveryAddress,
    setLastRemovedItem,
    setOrderIsUpdating,
    setOrderProcessingState,
    setCarryOutPickupName,
    setOrderNote,
    setTempCateringStoreSlug,
    setIsOutOfRangeModalOpen,
    setIsLargeOrder,
    setPreferredStoreSlug,

    // Order-related actions
    upsertOrderItems,
    finalizeOrder,
    clearOrder,
    kickoffOrderCapture,
    checkCaptureStatus,
    checkFulfillmentTime,
    handleValidateFulfillmentTime,
    getNextAvailableTimeSlot,
  };

  return (
    <OrderContextNew.Provider value={contextValue}>
      {children}
      <ExtraBakeTimeModal
        isOpen={isOpenExtraBakeTimeModal}
        tempAdjustedFulfillmentTime={tempAdjustedFulfillmentTime}
        adjustFulfillmentTimeLoading={adjustFulfillmentTimeLoading}
        onClose={handleCloseExtraBakeTimeModal}
        onClick={handleAdjustFulfillmentTime}
        onSelectNewFlavorForNextWeek={handleSelectNewFlavorForNextWeek}
        setTempAdjustedFulfillmentTime={setTempAdjustedFulfillmentTime}
        earliestAvailableFulfillmentTime={earliestAvailableFulfillmentTime}
        fulfillmentModalDescription={fulfillmentModalDescription}
        orderTimeSlot={orderTimeSlot}
        isUpsertLoading={isLoading}
        orderLoading={orderLoading}
        tempBagTotal={tempBagTotal}
      />
      <OutOfRangeDeliveryModal
        isOpen={!!isOutOfRangeModalOpen}
        onClick={() => {
          setIsOutOfRangeModalOpen(false);
        }}
        onClose={() => {
          setIsOutOfRangeModalOpen(false);
          router.push(`/order/${SourceType.CarryOut.toLowerCase()}`);
        }}
      />
      <ReceiptRewardAnimationModal
        isOpen={showReceiptRewardAnimationModal}
        onClose={() => setShowReceiptRewardAnimationModal(false)}
      />
      {orderProcessingState.type !== OrderProcessingStateType.NotProcessing && (
        <CheckoutOrderProcessing
          orderProcessingState={orderProcessingState}
          goBack={() => {
            setOrderProcessingState({ type: OrderProcessingStateType.NotProcessing });
          }}
          tryAgain={() => {
            if (orderProcessingState.type === OrderProcessingStateType.FailedCapture) {
              kickoffOrderCapture();
            } else {
              // if the order is in a failed state, we need to finalize the order again
            }
          }}
        />
      )}
    </OrderContextNew.Provider>
  );
};

export const useOrderContextNew = () => {
  const context = useContext(OrderContextNew);

  if (!context) {
    throw new Error("useOrderContextNew must be used within OrderContextNewProvider");
  }

  return context;
};
