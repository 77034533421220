import { createContext, useContext, useState, useEffect, type ReactNode } from "react";
import { getFeatureFlag } from "@/static/lib/firebase";

// FEATURE FLAGS
const VALENTINES_DAY = "valentines_day";

interface FeatureFlagsContextValue {
  valentinesDay: boolean;
}

const FeatureFlagsContext = createContext<FeatureFlagsContextValue>({
  valentinesDay: false,
});

export const FeatureFlagsProvider = ({ children }: { children: ReactNode }) => {
  const [valentinesDay, setValentinesDay] = useState<boolean>(false);

  useEffect(() => {
    const fetchFeatureFlag = async () => {
      const flagValue = await getFeatureFlag(VALENTINES_DAY);
      setValentinesDay(flagValue);
    };

    fetchFeatureFlag();
  }, []);

  return <FeatureFlagsContext.Provider value={{ valentinesDay }}>{children}</FeatureFlagsContext.Provider>;
};

export const useFeatureFlags = () => {
  return useContext(FeatureFlagsContext);
};
