import { motion } from "framer-motion";
import { ImagesV2 } from "@/public/images/all";
import Image from "next/image";
import classNames from "classnames";

const RewardIcon = ({ animationStep, rewardSummary, upgraded }) => {
  const noNextTier = !rewardSummary?.nextTier;

  return (
    <motion.div layout initial={false} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
      <Image
        src={ImagesV2.rewardIconCelebrationLeft}
        alt=""
        width={400}
        height={400}
        className={classNames(
          "absolute duration-500 transform -translate-x-1/2",
          animationStep > 2 ? "w-[136px] h-[136px] -left-[80%]" : "w-[0px] h-[0px] -left-[60%]",
        )}
      />
      <Image
        src={ImagesV2.rewardIconCelebrationRight}
        alt=""
        width={400}
        height={400}
        className={classNames(
          "absolute duration-500 transform -translate-x-1/2",
          animationStep > 2 ? "w-[136px] h-[136px] left-[80%]" : "w-[0px] h-[0px]",
        )}
      />
      <Image
        src={
          upgraded || noNextTier ? rewardSummary?.tierAfterOrder?.badgeImageUrl : rewardSummary?.nextTier?.badgeImageUrl
        }
        alt={upgraded || noNextTier ? rewardSummary?.tierAfterOrder?.name : rewardSummary?.nextTier?.name}
        width={400}
        height={400}
        className={classNames(
          "transition-all duration-1000",
          animationStep > 1 ? "w-[136px] h-[136px] transform -translate-x-1/2" : "w-[30px] min-w-[30px] h-[30px]",
        )}
      />
    </motion.div>
  );
};

export default RewardIcon;
