import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Text from "components/v2/atoms/Text/Text";
import ProgressBar from "@/components/v2/atoms/ProgressBar/ProgressBar";

const RewardProgressBar = ({ rewardSummary, upgraded }) => {
  const { t } = useTranslation("receipt");
  const [progressPercentage, setProgressPercentage] = useState(0);
  const nextTierMinimumPointsRequired =
    rewardSummary.nextTier?.minimumPointsRequired || rewardSummary.tierAfterOrder?.minimumPointsRequired;
  const progressPercentageBeforeOrder =
    Number(((rewardSummary.cumulativeAnnualPointsBeforeOrder / nextTierMinimumPointsRequired) * 100).toFixed(2)) || 0;

  const progressPercentageAfterOrder = upgraded
    ? 100
    : Number(
        ((rewardSummary.cumulativeAnnualPointsAfterOrder / rewardSummary.nextTier.minimumPointsRequired) * 100).toFixed(
          2,
        ),
      ) || 0;

  const pointsToNextTier = Math.max(0, nextTierMinimumPointsRequired - rewardSummary.cumulativeAnnualPointsAfterOrder);
  const nextTier = rewardSummary.nextTier?.name || rewardSummary.tierAfterOrder?.name;

  useEffect(() => {
    const animationDuration = 2000;
    const startTime = Date.now();

    const animate = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / animationDuration, 1);

      const newProgress =
        progressPercentageBeforeOrder + (progressPercentageAfterOrder - progressPercentageBeforeOrder) * progress;

      setProgressPercentage(newProgress);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    animate();
  }, [progressPercentageBeforeOrder, progressPercentageAfterOrder]);

  return (
    <div className="flex flex-col w-full gap-1">
      <ProgressBar progressNumber={progressPercentage} totalNumber={100} color="black" />
      <div>
        <Text variant="body2" className="text-grey-60">
          {t("x_more_to_y_status", {
            x: pointsToNextTier,
            y: nextTier,
          })}
        </Text>
      </div>
    </div>
  );
};

export default RewardProgressBar;
