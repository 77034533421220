import classNames from "classnames";
import { motion } from "framer-motion";

export interface ProgressBarProps {
  color?: string;
  customColor?: string;
  progressNumber: number;
  totalNumber: number;
  customBackgroundStyle?: string;
  height?: string;
  hideAnimation?: boolean;
}

export default function ProgressBar({
  color,
  customColor,
  progressNumber,
  totalNumber,
  customBackgroundStyle,
  height,
  hideAnimation,
}: ProgressBarProps) {
  const percentageComplete = progressNumber >= totalNumber ? 100 : Math.floor((progressNumber / totalNumber) * 100);
  const percentageString = `${percentageComplete.toString()}%`;

  return (
    <div className={customBackgroundStyle || "w-full rounded-full border border-solid border-grey-20"}>
      <div style={{ width: percentageString }}>
        <motion.div
          initial={{
            width: hideAnimation ? "100%" : 0,
          }}
          animate={{ width: "100%" }}
          transition={
            hideAnimation
              ? {}
              : {
                  duration: 2,
                  delay: 0.5,
                  ease: "easeInOut",
                }
          }
          className={classNames(
            !!customColor ? customColor : `bg-${color || "black"}`,
            height ? `h-${height}` : "h-2.5",
            percentageComplete >= 95 ? "rounded-full" : "rounded-l-lg",
            percentageComplete === 0 ? "" : "border-l border-l-transparent",
          )}
        ></motion.div>
      </div>
    </div>
  );
}
