import { setToken } from "@/static/lib/xhr_legacy";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  type NormalizedCacheObject,
  Observable,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { fromPromise } from "@apollo/client/link/utils";
import { RefreshAccessTokenDocument } from "../generated/requests/services";
import { determineRegion, determineRegionFromDomain, fetchLocal, storeLocal } from "../static/lib/util";
import { getUserToken, isTokenExpired } from "./auth";

const MAX_RETRY_ATTEMPTS = 3;
const INITIAL_RETRY_DELAY = 500;
const RATE_LIMIT_TIMEOUT = 5 * 60 * 1000; // 5 minutes
export const RATE_LIMITED_UNTIL_KEY = "rateLimitedUntil";

export const BACKEND_URL = `${process.env.NEXT_PUBLIC_BACKEND_API}`;
export const POS_URL = `${process.env.NEXT_PUBLIC_POS_API}`;
/** @deprecated Use API_URL instead */
export const INTERNAL_URL = `${process.env.NEXT_PUBLIC_SERVICES_INTERNAL_API}`;
export const ANALYTICS_URL = `${process.env.NEXT_PUBLIC_ANALYTICS_API}`;
/** @deprecated Use API_URL instead */
export const AUTH_URL = `${process.env.NEXT_PUBLIC_SERVICES_AUTH_API}`;
/** @deprecated Use API_URL instead */
export const CUSTOMER_URL = `${process.env.NEXT_PUBLIC_SERVICES_CUSTOMER_API}`;
export const API_URL = process.env.NEXT_PUBLIC_API_URL;

export enum Service {
  api = "api",
  analytics = "analytics",
  /** @deprecated Use Service.api instead */
  auth = "auth",
  backend = "backend",
  /** @deprecated Use Service.api instead */
  internal = "internal",
  pos = "pos",
  /** @deprecated Use Service.api instead */
  customer = "customer",
}

const serviceUriMap: Record<Service, string> = {
  [Service.analytics]: ANALYTICS_URL,
  [Service.auth]: AUTH_URL,
  [Service.backend]: BACKEND_URL,
  [Service.internal]: INTERNAL_URL,
  [Service.pos]: POS_URL,
  [Service.customer]: CUSTOMER_URL,
  [Service.api]: API_URL,
};

const getService = (operation): ApolloLink => {
  const service = operation.getContext().service as Service;
  const uri = serviceUriMap[service] ?? INTERNAL_URL;
  return new HttpLink({ uri });
};

const serviceLink = ApolloLink.split(
  () => true,
  (operation, forward) => {
    const service = getService(operation);
    if (isTerminating(service)) {
      return service.request(operation) || Observable.of();
    } else {
      return service.request(operation, forward) || Observable.of();
    }
  },
);

const regionLink = new ApolloLink((operation, forward) => {
  const { locale } = operation.getContext();
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "x-crumbl-brand": process.env.NEXT_PUBLIC_BRAND_ID,
      "x-crumbl-region": determineRegion(locale),
      "Accept-Language": locale,
    },
  }));
  return forward(operation);
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const preflight = async () => {
    if (typeof window === "undefined") {
      return;
    }

    let token = getUserToken();
    const { shouldRefreshToken } = operation.getContext();
    if (token && isTokenExpired(token) && shouldRefreshToken) {
      operation.setContext(() => ({ refreshToken: false }));
      token = await refreshAccessToken();
    }

    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      shouldRefreshToken: true,
      headers: {
        ...headers,
        "x-crumbl-token": token,
      },
    }));
  };
  return fromPromise(preflight()).flatMap(() => forward(operation));
});

const refreshAccessToken = async () => {
  const refreshToken = fetchLocal("refreshToken");
  if (!refreshToken?.token || !client) {
    setToken(null);
    return null;
  }
  const response = await client.query({
    query: RefreshAccessTokenDocument,
    variables: {
      refreshToken: refreshToken.token,
      brandId: process.env.NEXT_PUBLIC_BRAND_ID,
      region: determineRegionFromDomain(window.location.hostname),
    },
    context: { service: Service.auth },
  });
  const accessToken = response?.data?.requestAccessToken?.accessToken;
  storeLocal("accessToken", accessToken);
  storeLocal("token", accessToken?.token);
  return accessToken?.token ?? null;
};

const refreshLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  let retryAttempt = 0;
  const retryDelay = INITIAL_RETRY_DELAY;

  const handleTokenError = async () => {
    try {
      const newToken = await refreshAccessToken();
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          "x-crumbl-token": newToken,
        },
      }));
    } catch {
      setToken(null);
    }
  };

  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (["Bad Token"].includes(err.message)) {
        setToken(null);
        return;
      }

      if (
        err.extensions?.code === "UNAUTHENTICATED" ||
        err.extensions?.code === "EXPIRED_SESSION" ||
        err.message === "Token Expired" ||
        err.message === "Authentication Required"
      ) {
        return fromPromise(handleTokenError()).flatMap(() => {
          // Invalidate the cache for the specific operation
          client.cache.evict({ fieldName: operation.operationName });
          // Retry the operation
          return forward(operation);
        });
      }
    }
  }

  const retryWithBackofff = (operation, forward, delay) => {
    return new Observable((observer) => {
      setTimeout(() => {
        forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      }, delay);
    });
  };

  if (networkError) {
    if ("statusCode" in networkError && networkError.statusCode === 429) {
      return new Observable((observer) => {
        const retry = () => {
          if (retryAttempt < MAX_RETRY_ATTEMPTS) {
            retryAttempt++;
            retryWithBackofff(operation, forward, retryDelay * 2 ** retryAttempt).subscribe({
              next: observer.next.bind(observer),
              error: retry,
              complete: observer.complete.bind(observer),
            });
          } else {
            storeLocal(RATE_LIMITED_UNTIL_KEY, Date.now() + RATE_LIMIT_TIMEOUT);
            observer.error({
              message: "Too many requests. Please try again later.",
              code: 429,
            });
          }
        };

        retry();
      });
    }
  }

  return forward(operation);
});

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) => (key === "__typename" ? undefined : value);
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation);
});

const cache = new InMemoryCache({
  /* https://www.apollographql.com/docs/react/caching/cache-configuration */
  typePolicies: {
    FullStoreInfo: { keyFields: ["storeId"] },
    Order: {
      keyFields: ["orderId"],
    },
    Account: { keyFields: ["accountId"] },
    Voucher: { keyFields: ["voucherId"] },
    Query: {
      fields: {
        store: {
          keyArgs: ["storeId", "slug"],
          merge(existing, incoming) {
            return { ...existing, ...incoming };
          },
        },
        customer: {
          keyArgs: false, // Ensure arguments are not used to distinguish cache entries
          merge(existing = {}, incoming, { mergeObjects }) {
            // Merge existing and incoming data for the 'customer' field
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
    StoreQueries: {
      merge(existing, incoming) {
        return { ...existing, ...incoming };
      },
      fields: {
        storeForSlug: {
          keyArgs: ["slug"],
          merge(cache, incoming, { args }) {
            // If we have incoming data, merge it with existing data
            return { ...cache, ...incoming };
          },
        },
        publicStoreInfo: {
          keyArgs: ["storeId"],
          merge(cache, incoming, { args }) {
            // If we have incoming data, merge it with existing data
            return { ...cache, ...incoming };
          },
        },
      },
    },
    PublicQueries: {
      merge(existing, incoming) {
        return { ...existing, ...incoming };
      },
      fields: {
        sourceForStore: {
          keyArgs: ["storeId", "type", "pickupDate", "options"],
        },
        order: {
          keyArgs: ["orderOrReceiptId"],
        },
      },
    },
    Source: {
      fields: {
        businessHoursForDay: {
          read(cache, { args, toReference, canRead }) {
            // If we have cached data for this date, return it
            const cacheMap = cache ? new Map(cache) : new Map();
            if (cache && cacheMap.has(args.date)) {
              return cacheMap.get(args.date);
            }
          },
          merge(cache, incoming, { args, mergeObjects }) {
            // Create a new object if cache is undefined
            const merged = cache ? new Map(cache) : new Map();
            // Store the incoming data under the specific date key
            merged.set(args.date, incoming);
            return merged;
          },
        },
      },
    },
    Customer: {
      keyFields: ["userId"],
      fields: {
        cards: {
          merge(existing, incoming) {
            return { ...existing, ...incoming };
          },
        },
      },
    },
    CustomerAccounts: {
      keyFields: ["currency", "userAccount", ["accountId"]],
    },
    PrivateCustomerQueries: {
      keyFields: false,
      fields: {
        customerReceiptsPaginated: {
          keyArgs: false,
          merge(existing = {}, incoming) {
            // Merge the receipts array
            const mergedReceipts = existing.receipts ? existing.receipts.slice(0) : [];
            mergedReceipts.push(...incoming.receipts);

            // Return the new field value
            return {
              cursor: incoming.cursor, // Use the new cursor
              receipts: mergedReceipts,
            };
          },
        },
        customersOrders: {
          keyArgs: false,
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
      },
    },
    UserAddress: {
      keyFields: ["addressId"],
    },
    ValidateFulfillmentTimeResult: {
      fields: {
        adjustedFullfillmentTime(stringValue: string) {
          return stringValue;
        },
      },
    },
    ProductCategory: {
      keyFields: ["productCategoryId"],
    },
    UserRewardSummary: {
      keyFields: ["userId"],
    },
    User: {
      keyFields: ["userId"],
    },
  },
});

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  name: "www",
  cache,
  version: "7.0.0",
  link: from([regionLink, refreshLink, authMiddleware, cleanTypenameLink, serviceLink]),
  headers: {
    "x-crumbl-region": typeof window !== "undefined" ? determineRegionFromDomain(window.location.hostname) : undefined,
  },
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy(currentFetchPolicy) {
        return currentFetchPolicy;
      },
    },
  },
});

export const serverClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  name: "www",
  cache: new InMemoryCache({
    resultCaching: false,
  }),
  link: from([regionLink, cleanTypenameLink, serviceLink]),
  ssrMode: true,
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
      context: {
        queryDeduplication: false,
      },
    },
  },
});

function isTerminating<T extends ApolloLink>(link: T) {
  return link.request.length <= 1;
}
