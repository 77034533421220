import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";
import CookieLoader from "../../atoms/CookieLoader/CookieLoader";
import Text from "../../atoms/Text/Text";
import { useOrderContextNew } from "../OrderContextNew/OrderContextNew";
import { fetchLocal, storeLocal } from "@/static/lib/util";

export type LayoutContextProps = {
  showSiteNav: boolean;
  setShowSiteNav: (value: boolean) => void;
  toggleSiteNav: () => void;
  hasOrder: boolean;
  showCart: boolean;
  setShowCart: (value: boolean) => void;
  toggleShowCart: () => void;
  showCta: boolean;
  setShowCta: (value: boolean) => void;
  toggleShowCta: () => void;
  setLoading: (loading: boolean, msg?: string) => void;
  isCarouselVisible: boolean;
  setIsCarouselVisible: (value: boolean) => void;
  showAllCarouselItems: boolean;
  setShowAllCarouselItems: (value: boolean) => void;
};

const LayoutContext = createContext<LayoutContextProps>({
  showSiteNav: false,
  setShowSiteNav: () => {},
  toggleSiteNav: () => {},
  hasOrder: false,
  showCart: false,
  setShowCart: () => {},
  toggleShowCart: () => {},
  showCta: false,
  setShowCta: () => {},
  toggleShowCta: () => {},
  setLoading: () => {},
  isCarouselVisible: false,
  setIsCarouselVisible: () => {},
  showAllCarouselItems: false,
  setShowAllCarouselItems: () => {},
});

const LS_SHOW_ALL_CAROUSEL_ITEMS = "crumblShowAllCarouselItems";

export function LayoutContextProvider({ children }) {
  const router = useRouter();
  const { t } = useTranslation();
  const { order } = useOrderContextNew();
  const [showCart, setShowCart] = useState(false);
  const [showSiteNav, setShowSiteNav] = useState(false);
  const [showCta, setShowCta] = useState(false);
  const [loading, setLoading] = useState<string>();
  const [isCarouselVisible, setIsCarouselVisible] = useState(false);
  const localStorageShowAllCarouselItems = fetchLocal<boolean>(LS_SHOW_ALL_CAROUSEL_ITEMS);
  const [showAllCarouselItems, setStateShowAllCarouselItems] = useState(localStorageShowAllCarouselItems || false);

  const setShowAllCarouselItems = (value: boolean) => {
    setStateShowAllCarouselItems(value);
    storeLocal(LS_SHOW_ALL_CAROUSEL_ITEMS, value);
  };

  const hasOrder = order?.items?.length > 0;

  useEffect(() => {
    if (typeof window !== "undefined") {
      setShowCart(router.asPath.includes("#cart"));
    }
  }, [router.asPath]);

  const handleLoading = (loading: boolean, msg = "") => {
    setLoading(loading ? msg || t("loading") : null);
  };

  const handleShowCart = (show: boolean) => {
    router.replace(show ? `${router.asPath}#cart` : router.asPath.split("#")[0], undefined, { shallow: true });
  };

  const contextValue: LayoutContextProps = {
    showSiteNav: showSiteNav,
    setShowSiteNav,
    toggleSiteNav: () => setShowSiteNav((prev) => !prev),
    hasOrder,
    showCart,
    setShowCart: handleShowCart,
    toggleShowCart: () => handleShowCart(!showCart),
    showCta,
    setShowCta,
    toggleShowCta: () => setShowCta((prev) => !prev),
    setLoading: handleLoading,
    isCarouselVisible,
    setIsCarouselVisible,
    showAllCarouselItems,
    setShowAllCarouselItems,
  };

  return (
    <LayoutContext.Provider value={contextValue}>
      {
        <>
          {children}
          {loading && (
            <div className="fixed z-50 inset-0 backdrop-blur">
              <div className="flex flex-col items-center justify-center w-full h-full">
                <div className="w-24 h-24 sm:w-32 sm:h-32 lg:w-36 lg:h-36">
                  <CookieLoader />
                </div>
                <Text variant="title3" className="capitalize">
                  {loading}
                </Text>
              </div>
            </div>
          )}
        </>
      }
    </LayoutContext.Provider>
  );
}

export function useLayoutContext() {
  const ctx = useContext(LayoutContext);

  if (!ctx) {
    throw new Error("useLayoutContext must be used within LayoutContextProvider");
  }

  return ctx;
}
