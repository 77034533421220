import React, { useEffect, useState } from "react";
import Modal from "components/v2/molecules/Modal/Modal";
import Text from "components/v2/atoms/Text/Text";
import Button from "components/v2/molecules/Button/Button";
import { ImagesV2, Icons } from "@/public/images/all";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import CookieJar from "components/v2/atoms/CookieJar/CookieJar";
import type { FormattedOrderReceipt } from "@/generated/requests/pos";
import classNames from "classnames";
import { useRouter } from "next/router";
import { useGetReceipt } from "../../../operations/queries";
import { AnimatePresence, motion } from "framer-motion";
import RewardIcon from "./ReceiptRewardIcon";
import ReceiptRewardProgressBar from "./ReceiptRewardProgressBar";

interface ReceiptRewardAnimationModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function ReceiptRewardAnimationModal({ isOpen, onClose }: ReceiptRewardAnimationModalProps) {
  const { t } = useTranslation("receipt");
  const [animationStep, setAnimationStep] = useState(1);
  const router = useRouter();
  const receiptId = router.query.receiptId as string;
  const { data } = useGetReceipt({ receiptId });
  const receipt = data?.public?.formattedOrderReceipt as FormattedOrderReceipt;
  const [loadingRewardsPage, setLoadingRewardsPage] = useState(false);

  const rewardSummary = receipt?.rewardSummary;
  const isHighestTierForNextYear =
    rewardSummary?.tierAfterOrder?.minimumPointsRequired <= rewardSummary?.cumulativeAnnualPointsAfterOrder;

  const backgroundColor =
    animationStep < 3
      ? rewardSummary?.tierBeforeOrder?.backgroundColor
      : rewardSummary?.tierAfterOrder?.backgroundColor;

  // upgraded tracks if their current (From last year compare to this year) tier has been upgraded + if they have reached Pink Tier again.
  const upgraded =
    rewardSummary?.tierBeforeOrder?.name !== rewardSummary?.tierAfterOrder?.name ||
    (!rewardSummary?.nextTier &&
      isHighestTierForNextYear &&
      rewardSummary?.tierAfterOrder?.minimumPointsRequired >= rewardSummary?.cumulativeAnnualPointsBeforeOrder);

  // alreadyHighestTier checks if they are already pink from last year
  const alreadyHighestTier =
    rewardSummary?.tierBeforeOrder?.name === rewardSummary?.tierAfterOrder?.name &&
    (!rewardSummary?.nextTier || rewardSummary?.nextTier?.name === rewardSummary?.tierAfterOrder?.name);

  const handleClickOnUnlockedRewardPerks = async () => {
    setLoadingRewardsPage(true);
    await router.push("/account?tab=rewards");
    setLoadingRewardsPage(false);
  };

  useEffect(() => {
    if (isOpen && receipt) {
      const interval = setInterval(
        () => {
          setAnimationStep((prevStep) => {
            if (!upgraded) {
              return 1;
            }
            if (prevStep === 1) {
              return 2;
            } else if (prevStep < 4) {
              return prevStep + 1;
            } else {
              return 4;
            }
          });
        },
        animationStep === 1 ? 3000 : animationStep === 2 ? 1500 : 500,
      );
      return () => clearInterval(interval);
    }
  }, [isOpen, animationStep, receipt, upgraded]);

  return (
    rewardSummary && (
      <Modal
        variant="basic"
        isOpen={isOpen}
        onClose={onClose}
        className={classNames("relative sm:!h-[600px]")}
        customContentStyles={backgroundColor ? { backgroundColor: backgroundColor } : undefined}
      >
        <AnimatePresence>
          <div
            className={classNames(
              "p-7 text-center flex flex-col gap-[20px] sm:max-w-[420px] h-[600px] mx-auto",
              animationStep !== 4 ? "justify-between" : "justify-end",
            )}
          >
            {/* Animation 1 */}
            <AnimatePresence>
              {animationStep === 1 && (
                <motion.div
                  key={1}
                  layout
                  initial={false}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                  className="h-[360px] flex flex-col justify-between gap-6"
                >
                  <div className="flex flex-col gap-[6px] z-20">
                    <Text variant="title2">{upgraded ? t("you_earned") : ""}</Text>
                    <Text variant="display3" className="text-nowrap">
                      {t("x_crumbs", { x: rewardSummary?.earnedPoints })}
                    </Text>
                    <Text variant="body1">
                      {t("new_balance_is_x", { x: rewardSummary?.cumulativeAnnualPointsAfterOrder })}
                    </Text>
                  </div>
                  <div className="relative max-w-[350px] mx-auto">
                    <Image src={ImagesV2.receiptCrumbModalBackgroundPiece} alt="" className="w-full scale-90" />
                    {rewardSummary?.cumulativeAnnualPointsAfterOrder && (
                      <CookieJar
                        state={rewardSummary?.cumulativeAnnualPointsAfterOrder}
                        classNames="absolute -top-[20%] h-full w-full object-contain z-10 scale-150"
                      />
                    )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {/* This part will persist through all animationSteps, that's why no AnimatePresence */}
            {alreadyHighestTier ? (
              <motion.div
                key={2}
                layout
                initial={false}
                transition={{ duration: 1 }}
                className={classNames(
                  "flex justify-center items-end gap-[6px]",
                  animationStep > 1 && "absolute top-10 left-[50%]",
                )}
              >
                <RewardIcon animationStep={animationStep} rewardSummary={rewardSummary} upgraded={upgraded} />
                {animationStep === 1 && (
                  <Text variant="body2" className="font-semibold pb-1">
                    {t("x_status", { x: rewardSummary?.tierAfterOrder?.name })}
                  </Text>
                )}
              </motion.div>
            ) : (
              <div className="flex flex-col gap-3">
                {animationStep === 1 && (
                  <Text variant="body2" className="font-semibold">
                    {t("x_status", { x: rewardSummary?.tierBeforeOrder?.name })}
                  </Text>
                )}
                <motion.div
                  key={2}
                  layout
                  initial={false}
                  transition={{ duration: 1 }}
                  className={classNames(
                    animationStep > 1 ? "absolute top-10 left-[50%]" : "flex justify-between gap-6",
                  )}
                >
                  {animationStep === 1 && (
                    <Image
                      src={rewardSummary?.tierBeforeOrder?.badgeImageUrl}
                      alt=""
                      width={30}
                      height={30}
                      className="w-[30px] h-[30px]"
                    />
                  )}
                  {animationStep === 1 && (
                    <ReceiptRewardProgressBar rewardSummary={rewardSummary} upgraded={upgraded} />
                  )}
                  <RewardIcon animationStep={animationStep} rewardSummary={rewardSummary} upgraded={upgraded} />
                </motion.div>
              </div>
            )}

            {/* Animation 1 */}
            <AnimatePresence>
              {animationStep === 1 && (
                <motion.div
                  key={3}
                  layout
                  initial={false}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                  className="flex flex-col gap-[20px]"
                >
                  {/* This will only show if you are pink from last year and not yet pink this year */}
                  {alreadyHighestTier && !isHighestTierForNextYear && (
                    <Text variant="body2">
                      {t("earn_x_crumbs_to_keep_status_for_y", {
                        x:
                          rewardSummary?.nextTier?.minimumPointsRequired -
                          rewardSummary?.cumulativeAnnualPointsAfterOrder,
                        y: rewardSummary?.tierAfterOrder?.name,
                      })}
                    </Text>
                  )}

                  <div className="flex flex-col gap-[10px]">
                    <Button variant="primary" onClick={onClose} className="w-full m-auto">
                      {t("got_it")}
                    </Button>
                    <Text variant="caption" className="text-grey-60 italic">
                      {t("crumbs_awarded_when_order_is_filled")}
                    </Text>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Animation 4 */}
            <AnimatePresence>
              {animationStep === 4 && (
                <motion.div
                  key={4}
                  layout
                  initial={{ opacity: 1, y: 999 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                  className="flex flex-col justify-between h-[360px]"
                >
                  {alreadyHighestTier ? (
                    <div className="h-[140px] flex flex-col gap-[50px]">
                      <Text variant="display3">{t("congrats")}</Text>
                      <Text variant="title2">
                        {t("enjoy_x_status_through_y", {
                          x: rewardSummary?.tierAfterOrder?.name,
                          y: new Date().getFullYear() + 1,
                        })}
                      </Text>
                    </div>
                  ) : (
                    <div>
                      <Text variant="display4">
                        {t("x_status_unlocked", { x: rewardSummary?.tierAfterOrder?.name })}
                      </Text>
                      <div onClick={handleClickOnUnlockedRewardPerks}>
                        {rewardSummary?.tierAfterOrder?.rewardPerks?.map((reward) => (
                          <div
                            key={reward.rewardPerkId}
                            className="flex items-center justify-between p-[20px] sm:px-0 border-b cursor-pointer hover:bg-blue-200 "
                          >
                            <Image
                              src={rewardSummary?.tierAfterOrder?.badgeImageUrl}
                              alt=""
                              width={30}
                              height={30}
                              className="w-[30px] h-[30px]"
                            />
                            <span className="text-gray-800 font-medium">{reward.name}</span>
                            <Image
                              src={Icons.angleRightSmall}
                              alt=""
                              width={30}
                              height={30}
                              className="w-[30px] h-[30px]"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col gap-[10px]">
                    <Button variant="primary" onClick={onClose} className="w-full m-auto" disabled={loadingRewardsPage}>
                      {t("got_it")}
                    </Button>
                    <Text variant="caption" className="text-grey-60 italic">
                      {t("crumbs_awarded_when_order_is_filled")}
                    </Text>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </AnimatePresence>
      </Modal>
    )
  );
}
