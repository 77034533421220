import Rive from "@rive-app/react-canvas";

export default function CookieJar({ classNames, state }: { classNames?: string; state?: number }) {
  const jarFillState = () => {
    if (state >= 2000) return "100";
    if (state >= 1000) return "75";
    if (state >= 500) return "50";
    if (state >= 250) return "25";
    if (state > 0) return "25";
    return "0";
  };

  return <Rive src="/animations/cookie_jar.riv" stateMachines={jarFillState()} className={classNames} />;
}
