import { AppContextProvider } from "@/components/OldAppContext";
import { CustomerContextProvider } from "@/components/v2/contexts/CustomerContext/CustomerContext";
import { GeolocationContextProvider } from "@/components/v2/contexts/GeolocationContext/GeolocationContext";
import { GiftCardOrderContextProvider } from "@/components/v2/contexts/GiftCardOrderContext/GiftCardOrderContext";
import { LayoutContextProvider } from "@/components/v2/contexts/LayoutContext/LayoutContext";
import { OrderContextNewProvider } from "@/components/v2/contexts/OrderContextNew/OrderContextNew";
import { RewardsContextProvider } from "@/components/v2/contexts/RewardsContext/RewardsContext";
import { ToastContextProvider } from "@/components/v2/contexts/ToastContext/ToastContext";
import { FeatureFlagsProvider } from "@/components/v2/contexts/FirebaseContext/FirebaseContext";
import { UserTrackingConsentContextProvider } from "@/components/v2/contexts/UserTrackingConsentContext/UserTrackingConsentContext";
import { ErrorBoundary } from "@/components/v2/organisms/ErrorBoundary/ErrorBoundary";
import { ApolloProvider } from "@apollo/client";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { ModalThemeProvider } from "components/OldModal";
import { appWithTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { safeSessionStorage } from "static/lib/localstorage";
import { client } from "../lib/apollo";
import { CrumblSansFont } from "../lib/fonts";
import "../static/style/app.scss";

if (process.env.NEXT_PUBLIC_STAGE !== "dev") {
  datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DATADOG_TOKEN,
    service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
    forwardErrorsToLogs: true,
    sampleRate: Number.parseInt(process.env.NEXT_PUBLIC_DATADOG_SAMPLE_RATE) || 25,
    env: process.env.NEXT_PUBLIC_STAGE,
  });

  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APP_ID,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_TOKEN,
    service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
    site: "datadoghq.com",
    env: process.env.NEXT_PUBLIC_STAGE,
    sampleRate: 1,
    sessionSampleRate: 1,
    sessionReplaySampleRate: 0,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  console.log("Datadog RUM and Logs initialized.");
} else {
  console.log("Datadog RUM and Logs are disabled in development mode.");
}

type RegionMapRecord = {
  url: string;
  region: string;
  locale: string;
};

const regionMap: Record<string, RegionMapRecord> = {
  "en-US": { url: "https://crumblcookies.com", region: "US", locale: "en-US" },
  "en-CA": { url: "https://crumblcookies.ca", region: "CA", locale: "en-CA" },
};

export const getRegion = (locale: string) => regionMap[locale] || regionMap["en-US"];

const shouldRedirect = (region) => {
  if (
    window.location.href.includes("dev") ||
    window.location.href.includes("beta") ||
    window.location.href.includes("localhost") ||
    process.env.FORCE_LOCAL_API
  ) {
    return false;
  }
  return !window.location.href.includes(region.url) && safeSessionStorage.get("region") !== region.region;
};

function App({ Component, pageProps }) {
  const { lngDict, ...rest } = pageProps;
  const router = useRouter();
  const getLayout = Component.getLayout || ((page) => page);

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Handling for if we want to open the app/appstore
      const region = getRegion(router.locale || router.defaultLocale);
      localStorage.removeItem("stripePublishableKey");

      if (shouldRedirect(region)) {
        safeSessionStorage.set("region", region.region);
        router.push(region.url, undefined, { locale: region.locale });
      }
    }
  }, []);

  return (
    <div className={`${CrumblSansFont.variable}`}>
      <ApolloProvider client={client}>
        <ErrorBoundary>
          <AppContextProvider>
            <UserTrackingConsentContextProvider>
              <CustomerContextProvider>
                <FeatureFlagsProvider>
                  <OrderContextNewProvider>
                    <RewardsContextProvider>
                      <GiftCardOrderContextProvider>
                        <LayoutContextProvider>
                          <GeolocationContextProvider>
                            <ToastContextProvider>
                              <ModalThemeProvider>{getLayout(<Component {...rest} />, pageProps)}</ModalThemeProvider>
                            </ToastContextProvider>
                          </GeolocationContextProvider>
                        </LayoutContextProvider>
                      </GiftCardOrderContextProvider>
                    </RewardsContextProvider>
                  </OrderContextNewProvider>
                </FeatureFlagsProvider>
              </CustomerContextProvider>
            </UserTrackingConsentContextProvider>
          </AppContextProvider>
        </ErrorBoundary>
      </ApolloProvider>
    </div>
  );
}

export default appWithTranslation(App);
