import {
  ActiveStoreSmallDocument,
  AllActiveStoresForOrderDocument,
  ClosestPickupStoreDocument,
  DeliveryStoreForPlaceIdDocument,
  FetchStoreByProximityDocument,
  FetchStoreBySlugFullDocument,
  FetchStoreBySlugSlimDocument,
  FetchStoreForShippingDocument,
  FileUploadType,
  NearbyActiveStoresForOrderDocument,
  NearbyStoreDocument,
  RequestUploadUrlDocument,
  ShippingStoreByProximityDocument,
  StoreProximityType,
  UpsertAndGetUserAddressDocument,
} from "@/generated/requests/backend";
import {
  type CaptureOrderUpsert,
  type CaptureOrderUpsertResult,
  type CaptureStatus,
  CateringBookingInformationDocument,
  CustomerArrivedDocument,
  CustomerCashAccountDocument,
  FetchOrderIdByReceiptIdDocument,
  FetchOrderReceiptDocument,
  FetchShippingDatesDocument,
  FinalizeOrderWithValidationsUpsertDocument,
  GenericSourceForStoreDocument,
  KickoffOrderCaptureDocument,
  type KickoffResponse,
  NextAvailableDeliveryTimeSlotsDocument,
  type Order,
  OrderCaptureStatusDocument,
  type OrderError,
  OrderErrorCategory,
  OrderOrigin,
  type OrderPaymentIntentInput,
  type Query as PosQuery,
  ShippingRatesDocument,
  SourceBusinessHoursDocument,
  type SourceType,
  UpsertOrderDocument,
  UpsertOrderWithPaymentIntentDocument,
} from "@/generated/requests/pos";
import { Service, client, serverClient } from "@/lib/apollo";
import { usePOSMutation, usePOSQuery } from "@/lib/apollo-hooks";
import gql from "graphql-tag";

const fetchDeliveryStore = async (placeId) => {
  const response = await client.query({
    query: DeliveryStoreForPlaceIdDocument,
    variables: { placeId },
    context: { service: Service.backend },
  });

  return response.data?.storeProximity;
};

// deprecated, please use fetchNearbyActiveStores
const fetchNearbyStores = async (input) => {
  const response = await client.query({
    query: NearbyStoreDocument,
    variables: { input },
    context: { service: Service.backend },
  });

  return response.data?.closestStore;
};

const fetchNearbyActiveStores = async (latitude, longitude) => {
  const response = await client.query({
    query: NearbyActiveStoresForOrderDocument,
    variables: { latitude, longitude },
    context: { service: Service.backend },
  });

  return response.data?.activeStoresForCoordinates;
};

const fetchActiveStores = async (locale) => {
  const response = await serverClient.query({
    query: ActiveStoreSmallDocument,
    context: { service: Service.backend, locale },
  });

  return response.data?.allActiveStores;
};

const fetchSource = async (type: SourceType, storeId: string, pickupDate?: string) => {
  try {
    const response = await client.query({
      query: GenericSourceForStoreDocument,
      variables: { storeId, type, pickupDate },
      context: { service: Service.pos },
    });
    return response.data?.public?.sourceForStore;
  } catch (err) {
    console.error({ err });
  }
};

const SourceBusinessStoreHoursQuery = gql`
  query SourceBusinessHours($storeId: ID!, $type: SourceType!, $selectedDate: Date!, $addressId: ID!) {
    public {
      sourceForStore(storeId: $storeId, type: $type) {
        businessHoursForDay(date: $selectedDate) {
          timezone
          deliveryTimeSlots(input: { storeId: $storeId, addressId: $addressId }) {
            startTimestamp
            endTimestamp
            isAvailable
            isAsap
          }
        }
      }
    }
  }
`;

export const fetchDeliverySourceBusinessHours = async (
  type: SourceType | string,
  storeId: string,
  selectedDate: string,
  addressId: string,
): Promise<PosQuery["public"]["sourceForStore"]> => {
  try {
    if (!storeId || !type || !selectedDate || !addressId) {
      return null;
    }

    const result = await usePOSQuery(SourceBusinessStoreHoursQuery, {
      variables: { storeId, type, selectedDate, addressId },
    });

    return result.data?.public?.sourceForStore;
  } catch (error) {
    console.error({ error });
    return null;
  }
};

export const fetchSourceBusinessHours = async (storeId: string, type: SourceType, selectedDate: string) => {
  if (!storeId || !selectedDate) {
    return null;
  }

  const response = await client.query({
    query: SourceBusinessHoursDocument,
    variables: { storeId, type, selectedDate },
    context: { service: Service.pos },
  });

  return response.data?.public?.sourceForStore;
};

const fetchStoreFromCoordinates = async (
  latitude: number | string,
  longitude: number | string,
  type: StoreProximityType,
) => {
  const response = await client.query({
    query: FetchStoreByProximityDocument,
    variables: { latitude: Number(latitude), longitude: Number(longitude), type },
    context: { service: Service.backend },
  });

  return response.data?.storeForCoordinates;
};

const fetchNearestStore = async (latitude, longitude) => {
  const response = await client.query({
    query: ClosestPickupStoreDocument,
    variables: { latitude: Number(latitude), longitude: Number(longitude) },
    context: { service: Service.backend },
  });

  return response.data?.storeForCoordinates;
};

const fetchShippingStore = async ({ googlePlaceId = "", addressId = "" }) => {
  if (!googlePlaceId && !addressId) {
    return null;
  }

  const response = await client.query({
    query: ShippingStoreByProximityDocument,
    variables: { input: { type: StoreProximityType.Shipping, addressId, googlePlaceId } },
    context: { service: Service.backend },
  });

  return response.data?.storeProximity;
};

const fetchShippingRates = async (orderId) => {
  try {
    const response = await client.query({
      query: ShippingRatesDocument,
      variables: { orderId },
      context: { service: Service.pos },
    });

    return response.data?.public?.shippingRates;
  } catch (error) {
    console.error({ error });
  }
};

// Add the payment intent info when we're ready to not modify the order anymore
const upsertOrder = async (
  upsert: CaptureOrderUpsert,
  paymentIntentInput?: OrderPaymentIntentInput,
  abortSignal?: AbortSignal,
): Promise<UpsertOrderResponse> => {
  upsert.items = upsert.items || [];
  upsert.fulfillment = upsert.fulfillment || {};
  upsert.origin = OrderOrigin.Web;

  const response = await client.mutate({
    mutation: paymentIntentInput ? UpsertOrderWithPaymentIntentDocument : UpsertOrderDocument,
    variables: { upsert, ...(paymentIntentInput && { paymentIntentInput }) },
    context: {
      service: Service.pos,
      ...(abortSignal && {
        fetchOptions: {
          signal: abortSignal,
        },
      }),
    },
  });

  const data = response.data?.public?.upsertOrder;

  return {
    errors: data?.errors,
    order: data?.order,
    // @ts-ignore
    ...(paymentIntentInput && { paymentIntent: data?.paymentIntentForOrder }),
  };
};

export const fetchOrderIdByReceiptId = async (receiptId: string) => {
  const response = await client.query({
    query: FetchOrderIdByReceiptIdDocument,
    variables: { receiptId },
    context: { service: Service.pos },
  });

  return response.data?.public?.order;
};

const fetchReceipt = async (orderId: string) => {
  const response = await client.query({
    query: FetchOrderReceiptDocument,
    variables: { orderId },
    context: { service: Service.pos },
  });

  const { shippingEstimate, orderReceipt, retrieveOrder } = response.data?.public || {};

  if (!orderReceipt || !shippingEstimate) {
    return null;
  }

  return { ...orderReceipt, ...retrieveOrder, shippingEstimate };
};

const fetchAllStores = async () => {
  const response = await client.query({
    query: AllActiveStoresForOrderDocument,
    context: { service: Service.backend },
  });

  return response.data?.allActiveStores;
};

//actually saves the address first then returns addressId & etc
const getAddressInfo = async (upsert) => {
  if (upsert?.latitude) {
    upsert.latitude = Number.parseFloat(upsert.latitude);
  }
  if (upsert?.longitude) {
    upsert.longitude = Number.parseFloat(upsert.longitude);
  }

  try {
    const response = await client.mutate({
      mutation: UpsertAndGetUserAddressDocument,
      variables: { upsert },
      context: { service: Service.backend },
    });

    return response.data?.privateMyself?.upsertAddress;
  } catch (error) {
    console.error(error);
  }
};

const customerArrived = async (input): Promise<void> => {
  await client.mutate({
    mutation: CustomerArrivedDocument,
    variables: { input },
    context: { service: Service.pos },
  });
};

const fetchNextAvailableDeliveryTimeSlots = async (storeId, addressId) => {
  const response = await client.query({
    query: NextAvailableDeliveryTimeSlotsDocument,
    context: { service: Service.pos },
    variables: {
      storeId,
      addressId,
    },
  });

  return response.data?.public?.sourceForStore?.nextAvailableBusinessHours?.deliveryTimeSlots;
};

const fetchCrumblCashAccount = async (currency: string) => {
  const response = await client.query({
    query: CustomerCashAccountDocument,
    variables: { currency },
    context: { service: Service.pos },
  });

  return response.data?.customer?.customer?.accountsByCurrency?.userAccount;
};

const captureOrder = async ({
  orderId,
  abortSignal,
}: {
  orderId: string;
  abortSignal?: AbortSignal;
}): Promise<KickoffResponse> => {
  const response = await client.mutate({
    mutation: KickoffOrderCaptureDocument,
    variables: { orderId },
    context: {
      service: Service.pos,
      ...(abortSignal && {
        fetchOptions: {
          signal: abortSignal,
        },
      }),
    },
  });

  return response.data?.public?.kickoffCapture;
};

const fetchCaptureStatus = async ({ orderId }): Promise<CaptureStatus | null> => {
  const response = await client.mutate({
    mutation: OrderCaptureStatusDocument,
    variables: { orderId },
    context: { service: Service.pos },
  });

  return response.data?.public?.orderCaptureStatus;
};

const finalizeOrderUpsert = async ({
  input,
  abortSignal,
}: {
  input: CaptureOrderUpsert;
  abortSignal?: AbortSignal;
}): Promise<CaptureOrderUpsertResult | null> => {
  const response = await client.mutate({
    mutation: FinalizeOrderWithValidationsUpsertDocument,
    variables: { input, categories: [OrderErrorCategory.All] },
    context: {
      service: Service.pos,
      ...(abortSignal && {
        fetchOptions: {
          signal: abortSignal,
        },
      }),
    },
  });

  // @ts-ignore
  return response.data?.public?.upsertOrder;
};

const fetchBookingInformation = async (storeId = "", start = "", end = "") => {
  const response = await client.query({
    query: CateringBookingInformationDocument,
    variables: { storeId, start, end },
    context: { service: Service.pos },
  });

  return response.data?.public?.cateringInformation?.days || [];
};

// export const fetchCateringSource = async (storeId = "", pickupDate?: string) => {
//   const response = await client.query({
//     query: CateringSourceDocument,
//     variables: { storeId, pickupDate },
//     context: { service: Service.pos },
//   });

//   const source = response.data?.public?.sourceForStore;

//   // Catering 2.0 needs to ignore the old, catering special type products
//   //
//   // Also ignore any `DELIVERY_FEE` – will be handled by the backend in the
//   // future.
//   const products =
//     source?.products
//       ?.filter((p) => ![CATERING, "DELIVERY_FEE"].includes(p.product?.specialType))
//       .map((p) => ({
//         ...p,
//         modifiersByType: keyBy(p?.product?.modifiers || [], "specialType"),
//       })) ?? [];

//   return { ...source, products };
// };

export const fetchShippingDates = async (orderId: string) => {
  const response = await client.query({
    query: FetchShippingDatesDocument,
    variables: { orderId },
    context: { service: Service.pos },
  });

  return response.data?.public?.shippingEstimate ?? {};
};

const SubmitOrderFeedbackMutation = gql`
  mutation SubmitOrderFeedback($input: OrderFeedbackSurveyInput!) {
    customer {
      submitOrderFeedback(input: $input) {
        feedbackStatus
      }
    }
  }
`;

export const submitOrderFeedback = async (orderId = "", responses = [], surveyComplete = false) => {
  if (!responses?.length) {
    return;
  }

  const result = await usePOSMutation(SubmitOrderFeedbackMutation, {
    variables: { input: { orderId, responses, surveyComplete } },
  });

  // @ts-ignore
  return result?.customer?.submitOrderFeedback?.feedbackStatus;
};

const OrderFeedbackQuestionsQuery = gql`
  query OrderFeedback($orderOrReceiptId: ID!) {
    public {
      order(orderOrReceiptId: $orderOrReceiptId) {
        orderId
        receiptId
        feedbackStatus
        feedbackQuestions {
          questionId
          text
          type
          crumbValue
          icon
          moreDetailTrigger
          moreDetailPrompt {
            headerText
            options
          }
        }
      }
    }
  }
`;

export const fetchFeedbackStatusQuestions = async (
  orderOrReceiptId = "",
): Promise<{
  orderId: Order["orderId"];
  receiptId: Order["receiptId"];
  status: Order["feedbackStatus"];
  questions: Order["feedbackQuestions"];
}> => {
  if (!orderOrReceiptId) {
    // @ts-ignore
    return;
  }

  const result = await usePOSQuery(OrderFeedbackQuestionsQuery, { variables: { orderOrReceiptId } });
  const data = result?.data?.public?.order;

  return {
    orderId: data?.orderId,
    receiptId: data?.receiptId,
    status: data?.feedbackStatus || "",
    questions: data?.feedbackQuestions || [],
  };
};

export const fetchFullStoreBySlug = async (slug = "") => {
  const response = await client.query({
    query: FetchStoreBySlugFullDocument,
    variables: { slug },
    context: { service: Service.backend },
  });

  return response.data?.storeBySlug;
};

export const fetchSlimStoreBySlug = async (slug = "") => {
  const response = await client.query({
    query: FetchStoreBySlugSlimDocument,
    variables: { slug },
    context: { service: Service.backend },
  });

  return response.data?.storeBySlug;
};

export const fetchStoreForShipping = async (addressId = "") => {
  const response = await serverClient.query({
    query: FetchStoreForShippingDocument,
    variables: { addressId },
    context: { service: Service.backend },
  });

  return response.data?.storeForShipping;
};

const CancelOrderMutation = gql`
  mutation CancelOrder($input: CancelOrderInput!) {
    customer {
      cancelOrder(input: $input) {
        wasCancelled
        notCancelledReason
      }
    }
  }
`;

//Image/Video
export const fetchUploadUrl = async (type = FileUploadType.Image) => {
  const response = await client.query({
    query: RequestUploadUrlDocument,
    variables: { type },
    context: { service: Service.backend },
  });

  return response?.data?.requestPrivateFileUploadUrl;
};

// export const cancelOrder = async (orderId: string): Promise<OrderCancellationResult> => {
//   const variables: { input: CancelOrderInput } = { input: { orderId } };
//   const result = await pos.mutate(CancelOrderMutation, { variables });

//   return result.customer.cancelOrder;
// };

export {
  captureOrder,
  customerArrived,
  fetchActiveStores,
  fetchAllStores,
  fetchBookingInformation,
  fetchCaptureStatus,
  fetchCrumblCashAccount,
  fetchDeliveryStore,
  fetchNearbyActiveStores,
  fetchNearbyStores,
  fetchNearestStore,
  fetchNextAvailableDeliveryTimeSlots,
  fetchReceipt,
  fetchShippingRates,
  fetchShippingStore,
  fetchSource,
  fetchStoreFromCoordinates,
  finalizeOrderUpsert,
  getAddressInfo,
  upsertOrder,
};

export type UpsertOrderResponse = {
  errors?: OrderError[];
  order?: any;
  paymentIntent?: {
    paymentIntentId: string;
    clientSecret: string;
    amount: number;
  };
};
