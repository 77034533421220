import { LS_ORDER } from "@/components/v2/contexts/OrderContextNew/OrderContextNew";
import {
  Currency,
  CustomerCashAccountDocument,
  CustomerOrderHistoryStoresDocument,
  CustomerPaymentMethodsDocument,
  CustomerReceiptsPaginatedDocument,
  FetchOrderReceiptDocument,
  FetchOrderReceiptV2Document,
  GenericSourceForStoreDocument,
  GetVoucherDocument,
  MyActiveVouchersDocument,
  OrderDocument,
  SourceBusinessHoursDocument,
  type SourceForStoreOptions,
  SourceType,
  StoreSourceForLocationSelectorDocument,
  GiftcardFromCodeDocument,
  GiftingSourceDocument,
  DigitalGiftCardByIdDocument,
  RecipientListDocument,
} from "@/generated/requests/pos";
import {
  FetchStoreBySlugFullDocument,
  NearbyActiveStoresForCoordinatesWithNearbyStoresDocument,
  StoreByStoreIdDocument,
  StoreSourcesByStoreIdDocument,
  UserRewardSummaryDocument,
  CorpMarketingCarouselItemsDocument,
  MeDocument,
  FlavorProfileDocument,
  BirthdayVoucherDetailsDocument,
  PosNutritionItemsDocument,
  StoreInfoSearchDocument,
} from "@/generated/requests/services";
import { GetUserDocument, StoresForJobsPageDocument, UserAddressesDocument } from "@/generated/requests/backend";
import { useAPIQuery, useBackendQuery, useCustomerQuery, usePOSQuery } from "@/lib/apollo-hooks";
import { fetchLocal } from "@/static/lib/util";
import { getOptionsForCatering } from "@/lib/helpers";
import { useOrderContextNew } from "@/components/v2/contexts/OrderContextNew/OrderContextNew";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

const POLL_INTERVAL = 600000; // 10 minutes in milliseconds
dayjs.extend(utc);
dayjs.extend(timezone);

export const useGetOrder = () => {
  const orderId = fetchLocal<string>(LS_ORDER);
  const { data, loading, error, refetch, networkStatus } = usePOSQuery(OrderDocument, {
    variables: {
      orderOrReceiptId: orderId,
    },
    skip: !orderId,
    fetchPolicy: "cache-and-network",
    pollInterval: POLL_INTERVAL,
  });

  return { data, loading, error, refetch, networkStatus };
};

export const useGetStoreBySlug = ({ storeSlug }: { storeSlug: string }) => {
  const { data, loading, error } = useAPIQuery(FetchStoreBySlugFullDocument, {
    variables: {
      slug: storeSlug,
    },
    skip: !storeSlug,
    fetchPolicy: "cache-first",
    pollInterval: POLL_INTERVAL,
  });

  return { data: data?.stores?.storeForSlug, loading, error };
};

export const useGetSource = ({
  storeId,
  type,
  timeSlot,
  options = undefined,
}: {
  storeId: string;
  type: SourceType;
  timeSlot: string;
  options?: SourceForStoreOptions;
}) => {
  const { isLargeOrder } = useOrderContextNew();
  if (isLargeOrder) {
    const { options: newOptions } = getOptionsForCatering(options);
    options = {
      ...options,
      ...newOptions,
    };
  }

  const { data, loading, error } = usePOSQuery(GenericSourceForStoreDocument, {
    variables: {
      storeId: storeId,
      type: type,
      pickupDate: timeSlot,
      ...(options && { options }),
    },
    skip: !type || !storeId,
    fetchPolicy: "cache-first",
    pollInterval: POLL_INTERVAL,
  });

  return { data, loading, error };
};

export const useGetSourceAllowedDaysToOrder = ({
  storeId,
  sourceType,
  options = undefined,
}: {
  storeId: string;
  sourceType: SourceType;
  options?: SourceForStoreOptions;
}) => {
  const { isLargeOrder } = useOrderContextNew();
  if (isLargeOrder) {
    const { options: newOptions } = getOptionsForCatering(options);
    options = {
      ...options,
      ...newOptions,
    };
  }

  const { data, loading, error } = usePOSQuery(StoreSourceForLocationSelectorDocument, {
    variables: {
      storeId: storeId,
      type: sourceType,
      ...(options && { options }),
    },
    skip: !storeId || !sourceType,
    pollInterval: POLL_INTERVAL,
  });

  return { data, loading, error };
};

export const useGetStoreById = ({ storeId }: { storeId: string }) => {
  const { data, loading, error } = useAPIQuery(StoreByStoreIdDocument, {
    variables: {
      storeId: storeId,
    },
    skip: !storeId,
    pollInterval: POLL_INTERVAL,
  });

  return { data: data?.stores?.publicStoreInfo, loading, error };
};

export const useGetStoreSources = ({ storeId }: { storeId: string }) => {
  const { data, loading, error } = useAPIQuery(StoreSourcesByStoreIdDocument, {
    variables: {
      storeId: storeId,
    },
    skip: !storeId,
    pollInterval: POLL_INTERVAL,
  });

  return { data: data?.stores.store, loading, error };
};

export const useGetCustomerCashAccount = ({ customerId, currency }: { customerId: string; currency?: string }) => {
  const { data, loading, error } = usePOSQuery(CustomerCashAccountDocument, {
    variables: {
      currency: currency || Currency.Usd,
    },
    skip: !customerId,
  });

  return { data, loading, error };
};

export const useGetCustomerPaymentMethods = ({
  customerId,
  stripeRegion,
}: {
  customerId: string;
  stripeRegion: string;
}) => {
  const { data, loading, error, refetch } = usePOSQuery(CustomerPaymentMethodsDocument, {
    variables: {
      stripeRegion: stripeRegion || "US",
    },
    skip: !stripeRegion || !customerId,
  });

  return { data, loading, error, refetch };
};

export const useGetCustomerOrderHistoryStores = ({ customerId }: { customerId: string }) => {
  const { loading, data, error } = usePOSQuery(CustomerOrderHistoryStoresDocument, {
    skip: !customerId,
  });

  return {
    data: data?.customer?.customersOrders,
    loading,
    error,
  };
};

export const useGetVouchers = ({ customerId, timeSlot }: { customerId: string; timeSlot?: string }) => {
  const { data, loading, error } = usePOSQuery(MyActiveVouchersDocument, {
    variables: {
      pickupDate: timeSlot,
    },
    skip: !customerId || !timeSlot,
    fetchPolicy: "cache-first",
  });

  return { data, loading, error };
};

export const useGetSourceBusinessHours = ({
  storeId,
  type,
  selectedDate,
  options = undefined,
  shouldSkip = false,
}: {
  storeId: string;
  type: SourceType;
  selectedDate: string;
  options?: SourceForStoreOptions;
  shouldSkip?: boolean;
}) => {
  const { isLargeOrder } = useOrderContextNew();
  if (isLargeOrder) {
    const { options: newOptions } = getOptionsForCatering(options);
    options = {
      ...options,
      ...newOptions,
    };
  }

  const { data, loading, error } = usePOSQuery(SourceBusinessHoursDocument, {
    variables: {
      storeId: storeId,
      type: type,
      selectedDate: selectedDate,
      ...(options && { options }),
    },
    skip: !storeId || !type || !selectedDate || shouldSkip,
    pollInterval: POLL_INTERVAL,
  });

  return { data, loading, error };
};

export const useGetReceipt = ({ receiptId }: { receiptId: string }) => {
  const { data, loading, error, refetch, startPolling, stopPolling } = usePOSQuery(FetchOrderReceiptV2Document, {
    variables: { orderId: receiptId },
    skip: !receiptId,
    // because the receipt can change when a customer arrives, or their order is updated
    fetchPolicy: "no-cache",
  });

  // Catering source type means this order was placed on POS, it will always be carryout out.
  // We still have isCatering flag on to indicate that this was a catering order.
  if (data?.public?.formattedOrderReceipt?.source?.type === SourceType.Catering) {
    data.public.formattedOrderReceipt.source.type = SourceType.CarryOut;
  }

  return { data, loading, error, refetch, startPolling, stopPolling };
};

export const useGetOldReceipt = ({ receiptId }: { receiptId: string }) => {
  const { data, loading, error, refetch } = usePOSQuery(FetchOrderReceiptDocument, {
    variables: { orderId: receiptId },
    skip: !receiptId,
    // because the receipt can change when a customer arrives, or their order is updated
    fetchPolicy: "no-cache",
  });

  return { data, loading, error, refetch };
};

export const useGetVoucher = ({ voucherId }: { voucherId: string }) => {
  const { data, loading, error } = usePOSQuery(GetVoucherDocument, {
    variables: { code: voucherId },
    skip: !voucherId,
  });

  return { data, loading, error };
};

export const useGetOrderHistoryPaginated = ({ customerId, limit = 20 }: { customerId: string; limit: number }) => {
  const { loading, data, error, fetchMore, networkStatus } = usePOSQuery(CustomerReceiptsPaginatedDocument, {
    variables: { limit: limit },
    skip: !customerId,
    notifyOnNetworkStatusChange: true,
  });

  return { data, loading, error, fetchMore, networkStatus };
};

export const useGetCustomerRewardSummary = ({
  customerId,
}: {
  customerId: string;
}) => {
  const {
    data,
    loading: rewardSummaryLoading,
    refetch,
  } = useCustomerQuery(UserRewardSummaryDocument, {
    variables: {},
    skip: !customerId,
  });
  return { data, rewardSummaryLoading, refetch };
};

export const useGetNearbyStores = ({ latitude, longitude }: { latitude: number; longitude: number }) => {
  const { data, loading, error } = useCustomerQuery(NearbyActiveStoresForCoordinatesWithNearbyStoresDocument, {
    variables: {
      coordinates: {
        latitude,
        longitude,
      },
    },
    skip: !latitude || !longitude,
  });

  return { data: data?.stores?.publicStoreInfoSearch, loading, error };
};

export const useGetGiftCardBalance = ({ giftcardId }: { giftcardId: string }) => {
  const { data, loading, error } = usePOSQuery(GiftcardFromCodeDocument, {
    variables: { code: giftcardId },
    skip: !giftcardId,
  });
  return { data, loading, error };
};

export const useGetUser = () => {
  const { data, loading, error, refetch } = useBackendQuery(GetUserDocument, {
    variables: {},
  });
  return { data, loading, error, refetch };
};

export const useGetCorpCarouselItems = ({ showAllCarouselItems }: { showAllCarouselItems: boolean }) => {
  const { data, loading, error } = useAPIQuery(CorpMarketingCarouselItemsDocument, {
    variables: {},
    skip: !showAllCarouselItems,
  });

  return { data, loading, error };
};

export const useGetMe = () => {
  const { data, loading, error } = useCustomerQuery(MeDocument);

  return { data, loading, error };
};

export const useGetFlavorProfile = ({ flavorId }: { flavorId: string }) => {
  const { data, loading, error } = useCustomerQuery(FlavorProfileDocument, {
    variables: { flavorId },
    skip: !flavorId,
  });

  return { data, loading, error };
};

export const useGetUserAddresses = ({ userId }) => {
  const { data, loading, refetch } = useBackendQuery(UserAddressesDocument, {
    variables: {},
    skip: !userId,
  });

  const refetchUserAddresses = async () => {
    const result = await refetch();
    return result.data?.me?.addresses;
  };

  return { data, loading, refetchUserAddresses };
};

export const useGetBirthdayVoucherDetails = () => {
  const { data, loading, error, refetch } = useCustomerQuery(BirthdayVoucherDetailsDocument, {
    variables: {},
  });

  return { data, loading, error, refetch };
};

export const useGetGiftCardOrderProducts = ({ currency }: { currency: Currency }) => {
  const { data, loading, error, refetch } = usePOSQuery(GiftingSourceDocument, {
    variables: { parameters: { currency } },
    skip: !currency,
  });

  return { data, loading, error, refetch };
};

export const useGetDigitalGiftCardById = ({ digitalGiftCardId }: { digitalGiftCardId: string }) => {
  const { data, loading, error, refetch } = usePOSQuery(DigitalGiftCardByIdDocument, {
    variables: { digitalGiftCardId },
    skip: !digitalGiftCardId,
  });

  return { data, loading, error, refetch };
};

export const useGetRecipientList = ({ recipientListId }: { recipientListId: string }) => {
  const { data, loading, error, refetch, previousData } = usePOSQuery(RecipientListDocument, {
    variables: { recipientListId },
    skip: !recipientListId,
  });

  return { data, loading, error, refetch, previousData };
};

export const useGetPosNutritionItems = ({ storeId, includeCatering = false }) => {
  const { data, loading, error } = useCustomerQuery(PosNutritionItemsDocument, {
    variables: { storeId, includeCatering },
    skip: !storeId,
    fetchPolicy: "cache-first",
  });

  return { data, loading, error };
};

export const useGetStoresBySearchTerm = ({
  searchTerm,
  latitude,
  longitude,
}: { searchTerm: string; latitude?: number; longitude?: number }) => {
  const { data, loading, error } = useCustomerQuery(StoreInfoSearchDocument, {
    variables: { term: searchTerm, ...(latitude && longitude && { coordinates: { latitude, longitude } }) },
    skip: !searchTerm || searchTerm?.length < 3,
    fetchPolicy: "cache-first",
  });

  return { data, loading, error };
};

export const useGetStoresForJobsPage = () => {
  const { data, loading, error } = useBackendQuery(StoresForJobsPageDocument, {
    fetchPolicy: "no-cache",
  });

  return { data, loading, error };
};
