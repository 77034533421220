import type { Voucher } from "@/generated/requests/pos";
import type { Scalars, UserRewardSummary, RewardTier } from "@/generated/requests/services";
import type { DeepPartial } from "@apollo/client/utilities";
import { createContext, useContext } from "react";
import { useCustomerContext } from "../CustomerContext/CustomerContext";
import { useGetCustomerRewardSummary } from "../../operations/queries";
export type RewardSummary = DeepPartial<UserRewardSummary>;

export type RewardsContextType = {
  rewardSummary?: DeepPartial<RewardSummary>;
  rewardSummaryLoading: boolean;
  refetch: any;
  isHighestTier: boolean;
  nextTier: any;
  currentYear: number;
  isEarningForNextYear: boolean;
  currentTier: RewardTier;
  currentYearTier: RewardTier;
  currentYearNextTier: RewardTier;
  maxPointsForHighestTier: number;
  sortedAllTiers: RewardTier[];
};

export type UserRewardVoucher = {
  __typename?: "UserRewardVoucher";
  canRedeem: Scalars["Boolean"]["output"];
  product: Voucher;
};

const RewardsContext = createContext<RewardsContextType | null>(null);

export const RewardsContextProvider = ({ children }) => {
  const { customer } = useCustomerContext();
  const { data, rewardSummaryLoading, refetch } = useGetCustomerRewardSummary({
    customerId: customer?.userId,
  });

  const rewardSummary = data?.rewards?.rewardSummary;
  const currentYear = new Date().getFullYear();

  // find the next tier and if user is on the last tier
  const sortedAllTiers = [...(rewardSummary?.allTiers || [])]?.sort(
    (a, b) => a.minimumPointsRequired - b.minimumPointsRequired,
  );

  let nextTier = undefined;
  let isHighestTier = false;
  for (let i = 0; i < sortedAllTiers?.length; i++) {
    // if the next tier is the last tier, set it
    if (i === sortedAllTiers?.length - 1) {
      nextTier = sortedAllTiers[i];
      isHighestTier = true;
      break;
    }

    if (rewardSummary?.currentTier?.rewardTierId !== sortedAllTiers[i]?.rewardTierId) {
      continue;
    } else {
      nextTier = sortedAllTiers[i + 1];
      break;
    }
  }

  const currentTier = rewardSummary?.allTiers?.find(
    (tier) => tier.rewardTierId === rewardSummary?.currentTier?.rewardTierId,
  );
  const isEarningForNextYear = rewardSummary?.currentCumulativePoints < currentTier?.minimumPointsRequired;
  let currentYearTier = sortedAllTiers?.[0];
  let currentYearNextTier = undefined;
  for (let i = 0; i < sortedAllTiers?.length; i++) {
    if (i === sortedAllTiers?.length - 1) {
      currentYearTier = sortedAllTiers[i];
      break;
    }

    if (rewardSummary?.currentCumulativePoints >= sortedAllTiers[i + 1]?.minimumPointsRequired) {
      continue;
    } else {
      currentYearTier = sortedAllTiers[i];
      currentYearNextTier = sortedAllTiers[i + 1];
      break;
    }
  }

  const maxPointsForHighestTier = sortedAllTiers?.[sortedAllTiers?.length - 1]?.minimumPointsRequired;

  return (
    <RewardsContext.Provider
      value={{
        rewardSummary,
        rewardSummaryLoading,
        refetch,
        isHighestTier,
        nextTier,
        currentYear,
        isEarningForNextYear,
        currentTier,
        currentYearTier,
        currentYearNextTier,
        maxPointsForHighestTier,
        sortedAllTiers,
      }}
    >
      {children}
    </RewardsContext.Provider>
  );
};

export const useRewardsContext = () => {
  const context = useContext(RewardsContext);

  if (!context) {
    throw new Error("useRewardsContext must be used within RewardsContextProvider");
  }

  return context;
};
